import {Component, ElementRef, EventEmitter, Input, Output, SecurityContext, ViewChild} from '@angular/core';
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {AlertService} from "@services/alert.service";
import {DomSanitizer} from "@angular/platform-browser";

const maxFileSize = 10 * 1024 * 1024; // 10 MB limit

@Component({
    selector: 'app-import-sdl-mappings-modal',
    templateUrl: './import-sdl-mappings-modal.component.html',
    styleUrls: ['./import-sdl-mappings-modal.component.scss']
})
export class ImportSdlMappingsModalComponent {
    @Input() popup: NgxPopupComponent; // MANDATORY
    @ViewChild('fileInput') fileInput: ElementRef;
    @Output() callback = new EventEmitter<any>();

    fileName: string = '';
    isFileSelected: boolean = false;
    uploadedData: Array<[string, string]>;

    constructor(
        private _alertService: AlertService,
        private sanitizer: DomSanitizer
    ) {
    }

    onFileSelected(event: any): void {
        const file: File = event.target.files[0];
        if (!file) {
            return
        }
        this.fileName = file.name;
        if (file.type !== 'text/csv') {
            this._alertService.error("Please upload a CSV file", file.type)
            return
        }
        if (file.size > maxFileSize) {
            this._alertService.error('File is too large. Please upload a file smaller than 10 MB.');
            return
        }
        this.isFileSelected = true;
    }

    onUpload(): void {
        const file: File = (document.querySelector('input[type="file"]') as HTMLInputElement).files[0];

        if (file) {
            this.readFile(file);
        }
    }

    readFile(file: File): void {
        const reader = new FileReader();

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
            this._alertService.error("Failed to import file.")
        }

        reader.onload = (e: any) => {
            const fileContent = e.target.result;
            this.processCSVData(fileContent);
        };

        reader.readAsText(file);
    }

    processCSVData(fileContent: string): void {
        const rows = fileContent.split('\n');
        const data = [];

        rows.forEach(row => {
            const columns = row.split(',');
            if (columns.length === 2) {
                data.push([
                    this.sanitizer.sanitize(SecurityContext.HTML, columns[0].trim()),
                    this.sanitizer.sanitize(SecurityContext.HTML, columns[1].trim())
                ])
            }
        });

        this.uploadedData = data;

        this.callback.emit({
            fileData: this.uploadedData
        })
        this.closeModal();
    }

    closeModal() {
        this.popup.close();
    }

    deleteSelectedFile() {
        this.fileInput.nativeElement.value = "";
        this.fileName = '';
        this.isFileSelected = false;
    }

}
