import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SpreadingTemplateService} from "@services/spreading-template.service";
import {SharedDataService} from "@services/shared-data.service";
import {ConfirmationPopupComponent} from "@components/shared/popups/confirmation/confirmation-popup.component";
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";
import {forkJoin, Subscription} from "rxjs";
import {AutoUnsubscribe} from "../../../../../decorators/auto-unsubscribe";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {finalize} from "rxjs/operators";
import {AlertService} from "@services/alert.service";
import {
  ImportSdlMappingsModalComponent
} from "@components/main/spreading-template/edit-spreading-template/edit-standard-data-layer-mappings/import-export-sdl-mappings-modal/import-sdl-mappings-modal.component";

const fileNameSafeCharsRegex = /[^a-zA-Z0-9\-_]/g;  // matches all chars except alphanumeric, dashes and underscores

type SDLMappingObject = {
  [key: string]: string;
}

type SDLMappingArray = Array<[string, string]>;

@Component({
  selector: 'app-edit-standard-data-layer-mappings',
  templateUrl: './edit-standard-data-layer-mappings.component.html',
  styleUrls: ['./edit-standard-data-layer-mappings.component.scss', '../edit-spreading-template.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class EditStandardDataLayerMappingsComponent implements OnInit {
  subsArr$: Subscription[] = [];
  loading: boolean = false;
  saveInProgress: boolean = false;
  templateUuid: string;
  formGroup: FormGroup
  nonUniqueRefsInForm: string[];

  validRefs: string[] = []

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private spreadingTemplateService: SpreadingTemplateService,
    private _sharedDataService: SharedDataService,
    private _popupService: NgxPopupService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
  ) {
    // Track the key-value pairs in FormArray
    this.formGroup = this.formBuilder.group({
      refToSTDMappings: this.formBuilder.array([], {validators: this.uniqueRefValidator.bind(this)})
    });
  }

  ngOnInit() {
    this._sharedDataService.pageHeaderTitle$.next('Standard Data Layer Mapping');
    this.templateUuid = this._activatedRoute.snapshot.paramMap.get('template_uuid');
    this.loading = true;
    this.loadMappingData();
  }

  loadMappingData() {
    forkJoin([
      this.spreadingTemplateService.readStandardDataLayerMappings(this.templateUuid),
      this.spreadingTemplateService.getAllRefsInTemplate(this.templateUuid)
    ]).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(([sdlMappings, allRefs]) => {
        this.validRefs = allRefs;
        const mappingArray = this.processMappingResponse(sdlMappings.standard_data_layer_mapping)
        this.populateForm(mappingArray)
      }, err => {
        console.error('Failed to load SDL mappings: ', err)
      })
  }

  processMappingResponse(mappingResponse: SDLMappingObject): SDLMappingArray {
    return Object.entries(mappingResponse);
  }

  populateForm(mappingData: SDLMappingArray): void {
    this.refToSTDMappings.clear()
    for (const [key, value] of mappingData) {
      this.addMapping(key, value)
    }
    // Trigger validation after auto-populating form fields
    this.formGroup.markAllAsTouched();
  }

  get refToSTDMappings() {
    return (this.formGroup.get('refToSTDMappings') as FormArray);
  }

  addMapping(key: string = '', value: string = ''): void {
    const keyValuePairGroup = this.formBuilder.group({
      key: [key, [this.refExistsInTemplateValidator(), Validators.required]],
      value: [value, [Validators.required]]
    });
    this.refToSTDMappings.push(keyValuePairGroup);
  }


  removeKeyValuePair(index: number): void {
    this.refToSTDMappings.removeAt(index);
  }

  refExistsInTemplateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.validRefs.length == 0) {
        // We failed to populate the refs array and cannot validate the input
        return null
      }
      if (!this.validRefs.includes(control.value)) {
        return {invalidKey: true};
      }
      return null;
    };
  }

  uniqueRefValidator(formArray: AbstractControl): ValidationErrors | null {
    this.nonUniqueRefsInForm = [];
    if (!(formArray instanceof FormArray)) {
      return null;
    }
    const values = formArray.controls.map(control => control.get('key').value);
    this.nonUniqueRefsInForm = this.findNonUniqueValues(values)
    if (this.nonUniqueRefsInForm.length) {
      return {nonUniqueValues: true}
    }
    return null
  }

  findNonUniqueValues(arr: string[]): string[] {
    const valueCounts: Map<string, number> = new Map();
    const nonUniqueValues: Set<string> = new Set();

    // Count occurrences of each value and track non-unique values in a single pass
    arr.forEach(value => {
      const count = (valueCounts.get(value) || 0) + 1;
      valueCounts.set(value, count);
      if (count > 1) {
        nonUniqueValues.add(value);
      }
    });

    return Array.from(nonUniqueValues);
  }


  buildSubmitPayload(): { standard_data_layer_mapping: SDLMappingObject } {
    const formValue = this.refToSTDMappings.value
    let mappingObject = {};
    for (let field of formValue) {
      mappingObject[field.key] = field.value
    }
    return {
      standard_data_layer_mapping: mappingObject
    }
  }


  goBack(): void {
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Do you want to save your changes before exiting?',
        text: 'Any unsaved mapping changes will be discarded.',
        confirmButtonText: 'Save and Exit',
        cancelButtonText: 'Exit Without Saving'
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.saveStandardDataLayerMappings(true);
          } else {
            this.returnToTemplateEditor();
          }
        }
      },
    });
  }

  saveStandardDataLayerMappings(redirectAfterSuccess = false): void {
    this.saveInProgress = true;
    if (!this.formGroup.valid) {
      this.alertService.error('Unable to save mappings! Check the form for invalid fields.')
      this.formGroup.markAllAsTouched();
      this.saveInProgress = false;
      return
    }
    const savePayload = this.buildSubmitPayload();
    this.spreadingTemplateService.updateStandardDataLayerMappings(this.templateUuid, savePayload)
      .pipe(finalize(() => {
        this.saveInProgress = false;
      })).subscribe(res => {
      this.alertService.success('Standard Data Layer mappings saved successfully');
      if (redirectAfterSuccess) {
        this.returnToTemplateEditor();
      }
    }, error => {
      console.error('error saving standard data layer mappings: ', error);
      this.alertService.error('Failed to save Standard Data Layer mappings!')
    })
  }


  returnToTemplateEditor(): void {
    this._router.navigate(['../'], {relativeTo: this._activatedRoute})
  }

  openImportModal() {
    this._popupService.open(
      {
        componentType: ImportSdlMappingsModalComponent,
        outputs: {
          callback: (data) => {
            if (data?.fileData) {
              this.populateForm(data.fileData);
            }
          }
        }
      })
  }

  downloadMappingsCSV(): void {
    // Load the mapping data from the server again to avoid downloading any changes made in the UI
    this.loading = true;
    this.spreadingTemplateService.readStandardDataLayerMappings(this.templateUuid)
      .pipe(finalize(() => {
        this.loading = false
      })).subscribe(
      (res) => {
        const exportFileName = this.generateFileName(res.name)
        this.generateAndDownloadCSVFile(res.standard_data_layer_mapping, exportFileName);
      }, error => {
        console.error(error)
        this.alertService.error('Failed to generate CSV export');
      })
  }

  generateFileName(templateName: string): string {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const fileName = `standard_data_layer_mappings__${templateName}__${date}`;
    return fileName.replace(fileNameSafeCharsRegex, "");
  }

  generateAndDownloadCSVFile(mappingData, fileName) {
    let csvContent = '';
    for (const [key, value] of Object.entries(mappingData)) {
      csvContent += `${key},${value}\n`;
    }
    const blob = new Blob([csvContent], {type: 'text/csv'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}
