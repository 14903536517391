<div class="row title-bar-container">
    <div class="title-bar">
        <div class="template-name">
            <button class="btn btn-rounded btn-secondary back-button" (click)="goBack()"><i
                class="fas fa-chevron-left"></i> Back
            </button>
        </div>
        <div class="edit-spreading-template-buttons">
            <a class="import-export-button import-button" (click)="openImportModal()">Import</a>
            <a class="import-export-button export-button" (click)="downloadMappingsCSV()">Export</a>
            <button [disabled]="saveInProgress" (click)="saveStandardDataLayerMappings(false)"
                    class="btn btn-rounded save-template-button btn-primary">
                Save Mappings
            </button>
        </div>
    </div>
</div>
<div class="container-fluid mapping-editor-container">
    <form [formGroup]="formGroup" *ngIf="!loading">
        <div class="form-level-validation-message">
            <p *ngIf="refToSTDMappings.hasError('nonUniqueValues')">
                <i class="fas fa-exclamation-circle"></i>
                Error: Form contains duplicate mapping definitions. All Ref values must be unique
            </p>
        </div>
        <table class="table">
            <thead>
            <tr>
                <th class="col-sm-1 line-item-cell"></th>
                <th class="col-sm-4 line-item-cell">Template Item Ref</th>
                <th class="col-sm-2 fit-to-content-cell"><i class="fa-solid fa-right-long arrow-img-header"></i>
                </th>
                <th class="col-sm-4 mapping-cell">Standard Data Layer Mapping Key</th>
                <th class="col-sm-1 line-item-cell"></th>
            </tr>
            </thead>
            <tbody formArrayName="refToSTDMappings">
            <tr *ngFor="let keyValuePair of refToSTDMappings.controls; let i = index" [formGroupName]="i">
                <th class="col-sm-1 line-item-cell"></th>
                <td class="col-sm-4 line-item-cell">
                    <input class="form-control"
                           [ngClass]="{
                                'is-invalid': keyValuePair.get('key')?.invalid && keyValuePair.get('key')?.touched || nonUniqueRefsInForm.indexOf(keyValuePair.get('key').value) > -1}"
                           formControlName="key" placeholder="Key"/>
                    <span class="invalid-feedback">
                            <div *ngIf="keyValuePair.get('key')?.hasError('required')">Ref value is required.</div>
                            <div *ngIf="keyValuePair.get('key')?.hasError('invalidKey')">Ref does not exist in current template config.</div>
                            <div *ngIf="nonUniqueRefsInForm.indexOf(keyValuePair.get('key').value) > -1">Ref value must be unique.</div>
                        </span>
                </td>
                <th class="col-sm-2 fit-to-content-cell"><i class="fa-solid fa-right-long arrow-img-header"></i>
                <td class="col-sm-4 mapping-cell">
                    <input class="form-control"
                           [ngClass]="{'is-invalid': keyValuePair.get('value')?.invalid && keyValuePair.get('key')?.touched}"
                           formControlName="value" placeholder="Value"/>
                    <span class="invalid-feedback"
                          *ngIf="keyValuePair.get('value')?.invalid && keyValuePair.get('value')?.touched">
                        <div *ngIf="keyValuePair.get('value')?.hasError('required')">
                            Value cannot be empty. Remove row if mapping is not needed.
                        </div>
                    </span>
                </td>
                <th class="col-sm-1 line-item-cell">
                    <button type="button" class="btn btn-light-danger" (click)="removeKeyValuePair(i)"><i
                        class="fas fa-trash"></i>
                    </button>
                </th>
            </tr>

            </tbody>


        </table>
        <div class="add-button-container">
            <button type="button" class="btn btn-light-primary" (click)="addMapping()"><i class="fas fa-plus"></i>
                Add New Mapping
            </button>
        </div>
    </form>
    <ng-container *ngIf="loading">
        <div class="loader__overlay">
            <div class="loader"></div>
        </div>
    </ng-container>
</div>

