<div class="modal__header">
    <div class="modal__title sdl-upload-popup-header">
        Upload Standard Data Layer Mapping File
    </div>
</div>
<div class="modal__content sdl-upload-popup-text">
    <div class="modal-sub-title">
        Upload a CSV file containing REF to SDL Mappings. A file can be created manually, or by isolating the relevant
        columns in an Excel spreadsheet and 'saving as' a CSV file. Do not include any header rows or extraneous data
        as all rows in the file will be interpreted as mapping definitions.<br><br>
        Note: this will overwrite all standard data layer mappings for this template.
    </div>
    <div class="file-upload-container">
        <div class="input-group">
            <input #fileInput class="form-control" type="file" accept=".csv" (change)="onFileSelected($event)"/>
            <span class="input-group-text" [ngClass]="{'enabled': fileName, 'disabled': !fileName}">
                <i (click)="fileName && deleteSelectedFile()"
                   class="fas fa-trash form-control-feedback clear-field-icon"
                ></i>
            </span>
        </div>
    </div>
</div>

<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="closeModal()">Close</button>
    <button class="btn btn-primary" [disabled]="!isFileSelected" (click)="onUpload()">Upload</button>
</div>

